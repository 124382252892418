.grants{
  padding:165px 15px;
  background:url('/media/default/background2.jpg')no-repeat top center;
  background-size:cover;
  padding-bottom:60px;
  overflow: hidden;

  @media (max-width:$screen-md){
    padding-bottom:100px;
  }

  @media (max-width:$screen-sm){
    padding-top:25px;
    padding-bottom:0;
  }

  .main-container{
    position:relative;
  }

  &__header{
    font-size:4.2em;
    font-weight:300;
    font-style:italic;
    color:$white;
    text-align:center;
    position:relative;

    &::before{
      content:'';
      display:block;
      @extend .sprite;
      @extend .sprite.header-logo-w;
      position:absolute;
      top:-91px;
      right:0;
      left:0;
      margin:auto;
    }

    @media (max-width:$screen-sm){
      font-size:2.5em;
      &::before{
        display:none;
      }
    }
  }

  &__row{
    margin:0 -15px;
    margin-top: 140px;

    @media (max-width:$screen-md){
      margin-top:40px;
    }

    @media (max-width:$screen-sm){
      margin-top:30px;
    }
  }



  &__button{
    display:block;
    line-height:120%;
    background:$color4;
    width:100%;
    max-width:490px;
    font-size:2em;
    color:$black;
    padding: 17px 0;
    font-weight:500;
    margin-bottom: 20px;
    padding-left: 72px;
    padding-right: 75px;
    letter-spacing: 0.5px;
    position:relative;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;

    @media (max-width:$screen-md){
     margin-left:auto;
      margin-right:auto;
    }

      @media (max-width:$screen-sm){
        max-width:100%;
        padding:15px;
      }

    &::after{
      content:'';
      display:block;
      width:2px;
      height:46px;
      background:$black;
      right:73px;
      top:0;
      bottom:0;
      margin:auto;
      position:absolute;

      @media (max-width:$screen-sm){
        display:none;
      }

    }

    &:hover,&:focus{
      text-decoration: none;
      color:$black;
    }

    &:hover{
      opacity:0.8;
    }
  }

  &__icon{
    position:absolute;
    left:12px;
    top:0;
    bottom:0;
    margin:auto;

    @media (max-width:$screen-sm){
      display:none;
    }
  }

  &__pdf{
    position:absolute;
    right:15px;
    top:0;
    bottom:0;
    margin:auto;

    @media (max-width:$screen-sm){
      display:none;
    }
  }

  &__img{
   position:absolute;
    right: -134px;
    bottom: -151px;

    @media (max-width:$screen-lg){
      max-width:700px;
      right:-120px;
    }

    @media (max-width:$screen-md){
      max-width: 500px;
      right: -96px;
      bottom: -171px;
    }

    @media (max-width:$screen-sm){
      max-width: 340px;
      position:static;
      margin: 0 auto;
      display: block;

    }

  }


  &__content{
    margin-top: 42px;
  }

  &__contact{
    font-size:3.1em;
    color:$white;
    margin-bottom: 23px;

    @media (max-width:$screen-sm){
      font-size:2.5em;
    }
  }

  &__text{
    font-size:1.8em;
    color:$white;
    line-height:130%;

    @media (max-width:$screen-sm){
      font-size:1.5em;
    }
  }
}