.banner{
	position:relative;


	&__img{
		width:100%;
	}
	&__indicators{
		bottom: 18px !important;
		@media (max-width:$screen-lg){
			bottom: 15px !important;
		}

		@media (max-width:$screen-md){
			display:none;
		}




	}

	&__content{
		position:absolute;
		top:0;
		right:0%;
		left:0%;
		bottom:0;
		margin:auto;
		max-width: 1430px;
		width:100%;
		display: flex;
		flex-direction: column;
		z-index:1;
		margin-top: 7.5%;


		@media (max-width:$screen-lg){
			margin-top:5%;
		}

		@media (max-width:$screen-sm){
			margin:0;
			display: flex;
			align-items: center;
			justify-content: center;
		}





	}

	&__text{
		font-size:60px;
		color:$white;
		text-align:center;
		text-transform: uppercase;
		line-height:120%;
        font-weight:bold;
		padding: 10px 20px;

		@media (max-width:$screen-lg){
			font-size:40px;
		}

		@media (max-width:$screen-md){
			font-size:25px;
		}

		@media (max-width:$screen-sm){
			font-size:20px;
		}

		&--small{
			font-family: 'Open Sans', sans-serif;
			font-size:23px;
			text-transform: none;
            background:none;
           	font-weight:400;
			text-align:left;
			display:block;
			padding:0;
			margin:0 auto;
			margin-top: 32px;
			line-height:120%;
			text-align:center;
			letter-spacing:1px;
			max-width: 1030px;

			@media (max-width:$screen-lg){
				font-size:18px;
				margin-top:16px;
			}

			@media (max-width:$screen-md){
				font-size:16px;
			}

			@media (max-width:$screen-sm){
				display:none;
			}
		}
	}

	&__button{
		display: block;
		width: 330px;
		border-radius: 10px;
		border: 3px solid $white;
		text-transform: uppercase;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: $white;
		padding: 14px 0;
		position: absolute;
		left: 0;
		margin: 0 auto;
		right: 0;
		bottom: 100px;
		-webkit-transition: background-color 0.3s;
		-moz-transition: background-color 0.3s;
		-ms-transition: background-color 0.3s;
		-o-transition: background-color 0.3s;
		transition: background-color 0.3s;

		@media (max-width:$screen-lg){
			font-size:16px;
		}

		@media (max-width:$screen-md){
			font-size:14px;
			width:200px;
			padding:10px 0;
			bottom:55px;
		}

		@media (max-width:$screen-sm){
			display:none;
		}

		&:hover,&:focus{
			text-decoration:none;
			background:rgba(255,255,255,0.2);
		}
	}


	&__li{
		width:24px !important;
		height:24px !important;
		padding:0 !important;
		border:3px solid $color1 !important;
		background:none !important;
		border-radius:100% !important;
		margin:0 9px !important;
		background:$white !important;


		@media (max-width:$screen-sm){
			width:18px !important;
			height:18px !important;
			margin:0 3px !important;
			border-width: 2px !important;
		}

		&.active{
			width:24px !important;
			height:24px !important;
			margin:0 9px !important;
			padding:0 !important;
			background:$color1 !important;
			border:3px solid $white !important;

			@media (max-width:$screen-sm){
				width:18px !important;
				height:18px !important;
				margin:0 3px !important;
				border-width: 2px !important;
			}

		}


	}
}