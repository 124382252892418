.resolution{
  padding:175px 15px;
  background:$color5;
  padding-bottom:115px;

  @media (max-width:$screen-sm){
    padding-top:25px;
    padding-bottom:20px;
  }

  .main-container{
    padding:0;
  }

  &__header{
    font-size:4.2em;
    font-weight:300;
    font-style:italic;
    color:$color1;
    text-align:center;
    position:relative;

    &::before{
      content:'';
      display:block;
      @extend .sprite;
      @extend .sprite.header-logo;
      position:absolute;
      top:-91px;
      right:0;
      left:0;
      margin:auto;
    }

    @media (max-width:$screen-sm){
      font-size:2.5em;
      &::before{
        display:none;
      }
    }
  }

  &__row{
    margin:0 -15px;
    display:flex;
    margin-top:70px;

    @media (max-width:$screen-md){
      display:block;
    }

    @media (max-width:$screen-sm){
      margin-top:30px;
    }

  }

  &__col{
    padding:0 15px;
    width:50%;

    @media (max-width:$screen-md){
      width:100%;
    }
  }

  &__block{
    width:100%;
    height:247px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:$screen-md){
      max-width:600px;
      margin:0px auto;
      margin-bottom:30px;
    }

    @media (max-width:$screen-sm){
      height:200px;
    }



    &:hover,&:focus{
      text-decoration:none;
    }

    &:hover{
      .resolution__text{
        background:rgba(255, 255, 255, 0.2);
      }
    }

    &--first{
      background:url('/media/default/img1.jpg')no-repeat center center;
      background-size:cover;
    }

    &--second{
      background:url('/media/default/img2.jpg')no-repeat center center;
      background-size:cover;
    }
  }

  &__text{
    width:370px;
    height:129px;
    line-height:100%;
    border:3px solid $white;
    text-transform:uppercase;
    font-weight:bold;
    font-size:3.1em;
    color:$white;
    text-align:center;
    display:flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;


    @media (max-width:$screen-md){
      max-width:400px;
      height:150px;
    }

    @media (max-width:$screen-md){
      max-width:300px;
      height:150px;
      font-size:2.5em;
    }


  }

}