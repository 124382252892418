.alerts{
  background:$color1;
  padding: 27px 0;
  padding-bottom:15px;

  &__header{
    text-transform: uppercase;
    text-align:center;
    font-size:2.4em;
    color:$white;
    letter-spacing: 1px;

  }

  &__content{
    max-width: 760px;
    margin:0 auto;
    color:$white;
    font-size:1.7em;
    line-height:160%;
    text-align: left;
    padding: 19px 0;
    p{
      margin:0;
    }
  }
}

.alert{
  margin:20px 0;
  font-size:1.6em;

  &-danger{
    border:1px solid $dangerBorder;
    background:$dangerBg;
    color:$dangerColor;
  }

  &-success{
    border:1px solid $successBorder;
    background:$successBg;
    color:$successColor;
  }
}