.form {
  max-width: 540px;
  margin: 0 auto;
  margin-top:30px;
  padding-top:13px;

  &__label {
    display: block;
    font-weight: 300;
    color: $color3;
    margin-bottom:1px;
    font-size:1.6em;
    color:$black;
    margin-bottom:5px;

    &--checkbox {
      color:$color1;
      font-size:10px;
      line-height:160%;
    }
  }

  &__input {
    width: 100%;
    height: 38px;
    padding: 5px;
    border: 1px solid $color11;
    font-size: 14px;
    color: $color2;
    margin-bottom:23px;
    outline:none;

    &--textarea {
      max-width:540px;
      min-width:540px;
      max-height:190px;
      min-height:190px;
      margin-bottom:10px;

      @media (max-width:$screen-sm){
        max-width:100%;
        min-width:100%;
      }

    }
  }

  &__checkbox {
    margin: 0;
    position: relative;
    top: 3px;
  }

  &__more {
    font-weight:bold;
    color:$color2;
    &:hover,&focus{
      color:$color2;
    }
  }

  &__moreText {
    display:none;

  }

  &__text{
    font-size:10px;
    color:$color2;
    line-height:100%;
    text-align:center;
    margin-top:30px;

  }

  &__button {
    display:block;
    width:122px;
    padding:17px 5px;
    background:$color2;
    outline:none;
    color:$white;
    text-transform: uppercase;
    font-size:15px;
    text-align:center;
    margin:20px auto;
    border:0;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;

    &:hover,&:focus{
      background:$color3;
    }

  }

  &__loading{
    width:50px;
    height:50px;
    border:6px solid $color3;
    border-top:6px solid $color2;
    border-right:6px solid rgba(0,0,0,0);
    border-left:6px solid rgba(0,0,0,0);
    border-radius:100%;
    margin:20px auto;
    -webkit-animation: loading-animation 1s ease infinite;
    -o-animation: loading-animation 1s ease infinite;
    animation: loading-animation 1s ease infinite;
    display:none;
  }



}


@keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

