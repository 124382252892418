.header{
  background:$white;
  padding:16px 0;
  position:relative;
  z-index:101;

  @media (max-width:$screen-md){
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
  }

  @media (max-width:$screen-sm){
   padding:9px 0;
  }

  .main-container{
    position:relative;
  }

  &__title{
    max-width:320px;
    margin:0 auto;
    position:relative;

    @media (max-width:$screen-sm){
      max-width:245px;
    }

    a{
      text-decoration:none !important;
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      align-items: center;
    }
  }

  &__text{
    text-transform: uppercase;
    font-size:24px;
    color:$color1;
    display:inline-block;
    margin-left:10px;
    font-family: "HelveticaF";


    @media (max-width:$screen-sm){
      font-size:20px;
    }

  }


  &__img{
    @media (max-width:$screen-sm){
      max-width: 100px;
      margin: 10px 0;
    }
  }
}