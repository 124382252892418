.footer {
  background: $footer;
  padding: 50px 15px;

  .main-container {
    padding: 0;
  }

  &__text {
    color: $white;
    font-size: 1.2em;
    line-height: 170%;
  }
  &__links {
    margin-top: 35px;
  }

  &__link {
    color: $white;
    text-transform: uppercase;
    font-size: 1.4em;
    text-decoration: none;
    margin-right:4%;
    &:hover, &:focus {
      color: $white;
    }


    @media (max-width: $screen-sm) {
      display: block;
      padding: 0;
      margin: 0;
      border: 0;
      margin: 5px 0;
    }
  }

  &__realization {
    color: $white;
    font-size: 1.2em;
    margin-top: 23px;

  }

  &__img {
    position: relative;
    margin-left: 12px;
    position: relative;
    top: -2px;
  }

  &__logo {
    margin-bottom: 30px;
    a {
      text-decoration: none !important;
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      align-items: center;
    }
    &Text {
      text-transform: uppercase;
      font-size: 24px;
      color: $color1;
      display: inline-block;
      margin-left: 10px;
      font-family: "HelveticaF";
      max-width: 170px;
    }
  }

  &__row {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    margin: 0 -25px;


    @media (max-width:$screen-md){
      display:block;
      margin:0;
    }

  }

  &__col {
    padding: 0 25px;
    width:calc(100% - 385px);

    @media (max-width:$screen-md){
      width:100%;
      padding:0;
    }

    &--small{
      width:385px;

      @media (max-width:$screen-md){
        width:100%;
        padding:0;
      }
    }
  }

}

.footerMenu {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 35px;

  &__li {
    float: left;
    margin-right: 4%;

    @media (max-width:$screen-md){
      margin-bottom:10px;
    }
  }

  &__link {
    color: $white;
    text-transform: uppercase;
    font-size: 1.4em;
    text-decoration: none;

    &:hover,&:focus{
      color:$white;
    }
  }
}