.top{
  background:$color1;
  padding:7px 0;


  @media (max-width:$screen-sm){
    padding:5px 0;
  }

  &__container{
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    align-items: center;
    @media (max-width:$screen-md){
      padding:0 10px;
    }
    @media (max-width:$screen-sm){
      padding:0 7px;
    }
  }


  &__col{
    float:left;
    width:40%;

    @media (max-width:$screen-md){
      width:45%;
    }

    @media (max-width:$screen-sm){
      display:none;
    }


    &--right{
      width:60%;
      float:right;
      text-align:right;

      @media (max-width:$screen-md){
        width:55%;
      }

      @media (max-width:$screen-sm){
        width:100%;
        display:block;
      }
    }


  }

  &__text{
    color:$white;
    font-weight:bold;
    font-size:1.5em;

    @media (max-width:$screen-sm){
      text-align:center;
    }

    &--marker{
      position:relative;
      //&::before{
      //  content:'';
      //  display:block;
      //  @extend .sprite;
      //  @extend .sprite.small-marker;
      //  position:absolute;
      //  left:0px;
      //  top:0;
      //  bottom:0;
      //  margin:auto;
      //}
    }

  }

  &__markerIcon{
    display:inline-block;
    position: relative;
    top: 3px;
    margin-right: 5px;

    @media (max-width:$screen-md){
      display:none;
    }
  }


  &__phoneIcon{
    display: inline-block;
    margin-left: 13px;
    margin-right: 10px;
    position: relative;
    top: 4px;

    @media (max-width:$screen-md){
      display:none;
    }
  }

  &__link{
    color:$white;


    &--phone{
      position:relative;

      //&::before{
      //  content:'';
      //  display:block;
      //  @extend .sprite;
      //  @extend .sprite.small-phone;
      //  position:absolute;
      //  top:0;
      //  bottom:0;
      //  left:-27px;
      //  margin:auto;
      //}
    }

    @media (max-width:$screen-sm){
      margin-left:0;
    }

    &:hover,&:focus{
      color:$white;
    }
  }

  &__city{
    margin-right:15px;

    font-weight:400;

    @media (max-width:$screen-sm){
      display:none;
    }
  }

  &__block{
    @media (max-width:$screen-sm){
      display:inline-block;
    }
  }


  &__span{
    font-weight:400;

    @media (max-width:$screen-md){
      display:none;
    }
  }

}