.navigation{
  background:$color4;
  padding:40px 0;

  &__row{
    display: flex;
    flex-direction: row;
  }

  &__col{
    width:50%;
  }

  &__text{
    font-size:1.5em;
    color:$color2;
    font-weight:bold;
    text-transform: uppercase;
    margin-bottom:17px;

    @media (max-width:$screen-md){
      font-size:1.3em;
    }

    &--right{
      text-align:right;
    }
  }

  &__link{
    display:block;
    font-size:2.2em;
    font-weight:bold;
    color:$color2;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    max-width:245px;
    line-height:160%;
    position:relative;

    @media (max-width:$screen-md){
      font-size:1.4em;
    }

    &::before{
      content:'';
      display:block;
      width:30px;
      height:30px;
      border:2px solid $color2;
      border-width: 2px 0px 0 2px;
      position:absolute;
      top:0;
      bottom:0;
      margin:auto;

      @media (max-width:$screen-md){
        width:15px;
        height:15px;
      }
    }

    &--left{
      padding-left:50px;

      @media (max-width:$screen-md){
        padding-left:25px;
      }

      &::before{
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        left:7px;

        @media (max-width:$screen-md){
          left:2px;
        }
      }

    }

    &--right{
      padding-right:50px;
      float:right;

      @media (max-width:$screen-md){
        padding-right:25px;
      }

      &::before{
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        right:7px;

        @media (max-width:$screen-md){
          right:2px;
        }
      }
    }

    &:hover,&:focus{
      color:$color2;
      text-decoration:none;
    }

    &:hover{
      opacity:0.8;
    }
  }
}