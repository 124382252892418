@font-face {
  font-family: HelveticaF;
  src: url("../../fonts/Helvetica.otf");
}

html, body{
  font-family: 'Open Sans', sans-serif;

  @media (max-width:$screen-md){
    overflow-x:hidden;
  }

  font-size: 0.625em;
}

.html--font-small {
  font-size: 1em;
}

.html--font-medium {
  font-size: 1.5em;
}

.html--font-large {
  font-size: 2em;
}


h1,h2,h3,h4,h5,h6{
  margin:inherit;
}

html{
  overflow-x:hidden;
}


.overflow{
  overflow:hidden;
}

.clear{
  clear:both;
}

img{
  max-width:100%;
  height:auto;
}

.main-container{
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 15px;

  &--small{
    max-width: 1270px;
  }

  &--xsmall{
    max-width:785px;
    margin-top:65px;
  }
  &--footer{
    max-width:1445px;
  }

}

.no-margin{
  margin-top:0;
}


.pagination-block{
  text-align:center;
  margin-top:20px;
}

.pagination{
  &__li{


    &.active{
      .pagination__link{
        background:$color6;
        border-color:$color6;
        color:$white !important;

        &:hover{
          background:$color6;
          border-color:$color6;
        }
      }
    }
  }

  &__link{
    color:$color6 !important;

  }

  li{
    &.disabled{
      display:none;
    }
  }

  li > a{
    color:$color6 !important;
    margin:0 4px;
    font-size:1.6em !important;
  }

  li > span{
    margin:0 4px;
    font-size:1.6em !important;
  }
}


.scrollTop{
  width:50px;
  height:50px;
  background:$white;
  border-radius:100%;
  box-shadow:0 0 15px 0 rgba(0,0,0,0.7);
  position:fixed;
  right:20px;
  opacity:0.5;
  font-size:0;
  -webkit-transition: opacity 0.3s, bottom 07s;
  -moz-transition: opacity 0.3s, bottom 0.7s;
  -ms-transition: opacity 0.3s, bottom 0.7s;
  -o-transition: opacity 0.3s, bottom 0.7s;
  transition: opacity 0.3s, bottom 0.7s;
  bottom:-100px;

  @media (max-width:$screen-md){
    right:10px;
  }


  &.show{
    bottom:30px;
  }

  &:hover{
    opacity:1;
  }

  &::before{
    content:'';
    display:block;
    border-top:3px solid $color2;
    width:11px;
    height:11px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top:0;
    right:0;
    left:0;
    bottom:0;
    position:absolute;
    margin:auto;
  }
  &::after{
    content:'';
    display:block;
    border-top:3px solid $color2;
    width:11px;
    height:11px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top:0;
    right:0;
    left:0;
    bottom:0;
    position:absolute;
    margin:auto;
  }
}



.preloader{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  height:100%;
  width:100%;
  background:rgba(255,255,255,0.85);
  z-index:10000;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s;

  &__img{
    display:block;
    margin-bottom:30px;
  }

  &__content{
    animation:rotate 1s cubic-bezier(0.1, 0.8, 0.8, 0.1) infinite;
  }

  &__dot{
    width:20px;
    height:20px;
    border-radius:100%;
    border:3px solid $color1;
    display:inline-block;
    margin:5px;
    background:$color1;
    -webkit-transition:transform 1s;
    -moz-transition:transform 1s;
    -ms-transition:transform 1s;
    -o-transition:transform 1s;
    transition:transform 1s;
    animation: show-hide 1s ease infinite;

    &:nth-child(2){
      animation-delay: 300ms;
    }

    &:nth-child(3){
      animation-delay: 600ms;
    }
  }
}

@keyframes show-hide{
    0% {
        transform:scale(0);

    }
      50% {
        transform:scale(1);

      }
      100% {
        transform:scale(0);

      }
}

@keyframes rotate{
    0%{
      transform:rotate(0deg);
    }

    100%{
      transform:rotate(360deg);
    }
}

#preloader.preloader-hiding{
  opacity:0;
}

#preloader.preloader-hidden{
  display:none;
}


.alert{
  ul{
    padding-left:15px;
  }
}

.bottom{
  padding:50px 15px;
  padding-bottom:70px;
  background:$white;



  &__link{
    max-width:962px;
    margin:0 auto;
    background:$color1;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    color:$white;
    font-size:2.6em;
    text-transform: uppercase;
    line-height: 120%;
    font-weight:bold;
    padding:15px 40px;



    @media (max-width:$screen-md){
      font-size:1.6em
    }


    @media (max-width:$screen-sm){
      display:block;
      padding:15px;
    }




    &:hover,&:focus{
      color:$white;
    }
  }

  &__col{

    &--left{
      width:60%;
      padding:0 15px;

      @media (max-width:$screen-sm){
        width:100%;
      }
    }

    &--right{
      width:40%;
      padding:0 15px;
      padding-right:0;

      @media (max-width:$screen-sm){
        width:100%;
      }
    }
  }

  &__img{
    @media (max-width:$screen-sm){
      display:block;
      margin:0 auto;
      margin-top:20px;
    }
  }
}

.banner-page{
  width:100%;
  height:auto;
}


