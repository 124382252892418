.wcag {
  position: absolute;
  top: 0px;
  right: 8px;
  display:flex;
  z-index:1;

  @media (max-width:$screen-sm){
    position:static;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link {
    width: 30px;
    height: 30px;
    border: 2px solid $black;
    display: block;
    margin: 0 5px;
    position:relative;

    &:hover,&:focus{
      text-decoration:none;
    }

    &:hover,&.active{
      border-color:$color7;
      box-shadow:0 0 0 1px $color7 inset;

    }


      .wcag__icon {
        font-size: 18px;
        bottom: -2px;
      }


  }

  &__contrast{
    margin-right:15px;
    font-size:20px;
    background:$black;
  }

  &__normal{
    font-size:20px;
  }

  &__icon {
    position:absolute;
    right:0;
    left:0;
    margin:auto;
    color: $black;
    font-weight: bold;
    text-align:center;


      &--small{
        bottom:2px;
      }

      &--medium{
        bottom:1px;
      }

      &--large{
        bottom:-1px;
        left:2px;
      }

    &--contrast{
      color:$white;
    }


    sup{
      font-size: 13px;
      position: relative;
      top: -7px;
      right: 3px;
    }


  }



}