.docs{
  padding:152px 15px;
  background:$white;
  padding-bottom:30px;

  @media (max-width:$screen-sm){
    padding-top:25px;
    padding-bottom:0;
  }

  .main-container{
    padding:0;
  }

  &__header{
    font-size:4.2em;
    font-weight:300;
    font-style:italic;
    color:$color1;
    text-align:center;
    position:relative;

    &::before{
      content:'';
      display:block;
      @extend .sprite;
      @extend .sprite.header-logo;
      position:absolute;
      top:-91px;
      right:0;
      left:0;
      margin:auto;
    }

    @media (max-width:$screen-sm){
      font-size:2.5em;
      &::before{
        display:none;
      }
    }
  }

  &__title{
    font-size:3em;
    color:$color1;
    line-height:120%;
    position:relative;
    margin-top: 77px;
    margin-bottom: 58px;

    @media (max-width:$screen-sm){
      margin-top:30px;
      font-size:2em;
    }


    &::after{
      content:'';
      display:block;
      width:368px;
      height:1px;
      background:$color1;
      position:absolute;
      bottom:-12px;
      left:0;

      @media (max-width:$screen-sm){
       width:300px;
      }
    }
  }

  &__text{
    font-size:2em;
    color:$color8;
    margin-top:-30px;
    margin-bottom:30px;

    @media (max-width:$screen-md){
      font-size:1.5em;
    }
  }

&__content{
  margin-bottom:50px;
}

  &__download{
    font-size:2em;
    color:$color1;
    position:relative;
    padding-left:69px;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    @media (max-width:$screen-sm){
      font-size:1.5em;
    }

    &::before{
      content:'';
      display:block;
      position:absolute;
      left:0;
      top:0;
      bottom:0;
      margin:auto;

    }

    &:hover,&:focus{
      color:$color1;
      text-decoration: none;
    }

    &:hover{
      opacity:0.8;
    }
  }
}