.skipLinks{
  position:fixed;
  top:0;
  left:0;
  z-index:9999;
  &__link{
    display:block;
    font-size:2em;
    color:#ff0;
    background:#444444;
    text-decoration: none;
    padding:20px;
    text-align: center;
    width:300px;
    position:absolute;
    left:-999em;

    &:focus{
      text-decoration: none;
      color:#ff0;
      left:0;
    }
  }
}