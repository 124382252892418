.quality{
  padding: 165px 15px;
  padding-bottom:77px;
  background:url('/media/default/background.jpg')no-repeat top center;
  background-size:cover;



  &--white{
    background:$white;
    padding:155px 15px;
    padding-bottom:67px;
  }

  @media (max-width:$screen-sm){
    padding-top:25px;
    padding-bottom:20px;
  }

  .main-container{
    padding:0;
  }

  &__header{
    font-size:4.2em;
    font-weight:300;
    font-style:italic;
    color:$color1;
    text-align:center;
    position:relative;

    &::before{
      content:'';
      display:block;
      @extend .sprite;
      @extend .sprite.header-logo;
      position:absolute;
      top:-91px;
      right:0;
      left:0;
      margin:auto;
    }
    @media (max-width:$screen-sm){
      font-size:2.5em;
      &::before{
        display:none;
      }
    }

  }


  &__row{
    margin:0 -15px;
    display:flex;
    margin-top:68px;

    &--margin{
      margin-top:55px;
    }

    @media (max-width:$screen-sm){
      margin-top:30px;
    }

    @media (max-width:$screen-md){
      display:block;
    }
  }

  &__col{
    padding:0 15px;
    width:50%;
    display:flex;

    @media (max-width:$screen-md){
      width:100%;
    }
  }

  &__link{
    display:block;
    min-height:249px;
    width:100%;
    background:$white;
    border:2px solid $color1;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;

    @media (max-width:$screen-md){
      max-width:600px;
      margin:0 auto;
      margin-bottom:30px;
    }

    &:hover,&:focus{
      text-decoration: none;
    }

    &:hover{
      background:$color1;

      .quality__icon{
        opacity:0;

        &--white{
          opacity:1;
        }
      }

      .quality__title{
        color:$white;
      }
    }
  }

  &__image{
    position:relative;
    height:140px;
    &--first{
      margin-top:30px;
    }

    &--second{
      margin-top: 20px;
      margin-bottom: 24px;
    }

    &--third{
      margin-top: 44px;
      margin-bottom: -13px;
    }

    &--fourth{
      margin-top: 35px;
      margin-bottom: -4px;
    }
  }

  &__icon{
    display:block;
    margin:0 auto;
    position:absolute;
    top:0;
    left:0;
    right:0;
    margin:auto;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &--white{
      opacity:0;
    }


  }

  &__title{
    font-size:2.7em;
    margin:0;
    margin-bottom:20px;
    margin-top:14px;
    padding:0 15px;
    color:$color1;
    font-weight:400;
    text-align:center;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;

    @media (max-width:$screen-sm){
      font-size:2.5em;
    }
  }

  &__widget{
    max-width:947px;
    margin: 0 auto;
    margin-top: 70px;
  }

  &__iframe{
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
  }
}